import { request } from "./requestConfig.js"


export function loginRequest(data) {
	return request({
		url: "/login/",
		method: "post",
		data
	})
}


export function registerRequest(data) {
	return request({
		url: "/register/",
		method: "post",
		data
	})
}

export function getCodeRequest(randomdata) {
	return request({
		url: "/login/imgcode/" + randomdata,
		method: "get"
	})
}

export function getImageUrlRequest(randomdata) {
	return request({
		url: "/getimgurl/" + randomdata,
		method: "get"
	})
}

export function getHomeProducRequest(urldata) {
	return request({
		url: "/homeproducts/" + urldata,
		method: "get"
	})
}

export function getVipProductsRequest(data) {
	return request({
		url: "/newproduct/",
		method: "post",
		data
	})
}

export function getPaginationRequest(data) {
	return request({
		url: "/newproduct/",
		method: "post",
		data
	})
}

export function getPageRequest(url, val) {
	return request({
		url: "/newproduct/?" + url + "?limit=20&offset=" + val * 20,
		method: "get"
	})
}

export function getFollowRequest(urldata) {
	return request({
		url: "/follow/" + urldata,
		method: "get"
	})
}

export function postFollowRequest(data, val) {

	if (val) {
		val = '?page=' + val
	}else{
		val = ''
	}

	return request({
		url: "/follow/" + val,
		method: "post",
		data
	})
}

export function postNewProductRequest(data, val) {

	if (val) {
		val = '?page=' + val
	}else{
		val = ''
	}

	return request({
		url: "/newproduct/" + val,
		method: "post",
		data
	})
}

export function postBrandProductRequest(data, val) {

	if (val) {
		val = '?page=' + val
	}else{
		val = ''
	}

	return request({
		url: "/brandproduct/" + val,
		method: "post",
		data
	})
}


export function getNewProductRequest(data) {


	return request({
		url: "/newproduct/" + data,
		method: "get"
	})
}

// post获取ABA关键词产品数据!!!
export function postAbaProductRequest(data, val) {

	if (val) {
		val = '?page=' + val
	}else{
		val = ''
	}

	return request({
		url: "/abaproduct/" + val,
		method: "post",
		data
	})
}


// post获取ABA关键词产品数据!!!
export function postAbaKwRequest(data, val) {

	if (val) {
		val = '?page=' + val
	}else{
		val = ''
	}

	return request({
		url: "/abakw/" + val,
		method: "post",
		data
	})
}

// post获取ABA关键词产品数据!!!
export function postAbaKwFREERequest(data, val) {

	if (val) {
		val = '?page=' + val
	}else{
		val = ''
	}

	return request({
		url: "/abafree/" + val,
		method: "post",
		data
	})
}


// post获取ABA关键词产品数据!!!
export function postAbaitemonlyRequest(data, val) {

	if (val) {
		val = '?page=' + val
	}else{
		val = ''
	}

	return request({
		url: "/abaonly/" + val,
		method: "post",
		data
	})
}


// post获取ABA关键词产品数据!!!
export function postStoreProductRequest(data, val) {

	if (val) {
		val = '?page=' + val
	}else{
		val = ''
	}

	return request({
		url: "/store/" + val,
		method: "post",
		data
	})
}


export function getprofile(data) {
	return request({
		url: "/profile/",
		method: "post",
		data
	})
}

// getmyproductlist
export function getmyproductlist(urldata) {
	return request({
		url: "/myproducts/",
		method: "get"
	})
}

export function yanzhenRequest(urldata) {
	return request({
		url: urldata,
		method: "get"
	})
}

// 获取课程信息
export function getcourse(data) {
	return request({
		url: "/course/",
		method: "get",
		data
	})
}