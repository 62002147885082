<template>
	<div class="content">
		<div class="clear-fix">
			<el-form ref="account_form" :model="form">

				<div class="itemson-left fl">
					<div class="itemson-inner">
						<h2>选择产品类目</h2>
						<div class="itemson-left-cata">
							<p><el-checkbox v-model="form.allcheckbox" label="家用电器 : Appliances" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="工艺 : Arts, Crafts & Sewing"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="汽配 : Automotive" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="母婴 : Baby" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="美妆 : Beauty & Personal Care"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="摄影 : Camera & Photo Products"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="3C电子 : Cell Phones & Accessories"
									size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="服装 : Clothing, Shoes & Jewelry"
									size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="电脑周边 : Computers & Accessories"
									size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="电子 : Electronics" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="食品 : Grocery & Gourmet Food"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="手工 : Handmade Products" size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="个护 : Health & Household" size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="家居 : Home & Kitchen" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="工业 : Industrial & Scientific"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="厨具 : Kitchen & Dining" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="乐器 : Musical Instruments" size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="办公用品 : Office Products" size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="庭院用品 : Patio, Lawn & Garden"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="宠物用品 : Pet Supplies" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="户外运动品 : Sports & Outdoors" size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="运动记念品 : Sports Collectibles"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="工具 : Tools & Home Improvement"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="玩具 : Toys & Games" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="视频游戏 : Video Games" size="large" /></p>
						</div>
					</div>
				</div>

				<div class="itemson-right fl">
					<div class="right-form">

						<el-select v-model="form.country" placeholder="国家" class="fbamz">
							<el-option label="卖家所在国" value="" />
							<el-option label="所有" value="all" />
							<el-option label="美国" value="US" />
							<el-option label="中国" value="CN" />
						</el-select>

						<span class="kongge-new"> </span>
						<el-input v-model="form.address" placeholder="地址信息" class="storeaddress" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.feedbackbig" placeholder="店铺反馈大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.feedbacksmall" placeholder="店铺反馈小于" class="day-sales" />
						<span class="kongge-new"> </span>
						<el-input v-model="form.storestarbig" placeholder="店铺评分大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.storestarsmall" placeholder="店铺评分小于" class="day-sales" />

						<!-- <span class="kongge-new"> </span>
						<el-input v-model="form.sales1" placeholder="月销大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.sales2" placeholder="月销小于" class="day-sales" />
						<span class="kongge-new"> </span>
						<el-input v-model="form.price1" placeholder="价格大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.price2" placeholder="价格小于" class="day-sales" /> -->

						<!-- <span class="kongge-new"> </span>
						<el-input v-model="form.review1" placeholder="评价大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.review2" placeholder="评价小于" class="day-sales" /> -->

						<p class="pkongge"></p>
						<el-input v-model="form.storesalesbig" placeholder="店铺首页月销大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.storesalessmall" placeholder="店铺首页月销小于" class="day-sales" />
						<span class="kongge-new"> </span>

						<el-input v-model="form.storearreviewbig" placeholder="首页平均评价大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.storearreviewsmall" placeholder="首页平均评价小于" class="day-sales" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.storearrpricebig" placeholder="首页平均价格大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.storearrpricesmall" placeholder="首页平均价格小于" class="day-sales" />

						<p class="pkongge"></p>
						<el-input v-model="form.storeproductnumberbig" placeholder="店铺产品数量大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.storeproductnumbersmall" placeholder="店铺产品数量小于" class="day-sales" />
						<span class="kongge-new"> </span>

						<el-input v-model="form.storezifahuolvbig" placeholder="自发货比率大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.storezifahuolvsmall" placeholder="自发货比率小于" class="day-sales" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.storelanhaisalesbig" placeholder="店铺蓝海月销大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.storelanhaisalessmall" placeholder="店铺蓝海月销小于" class="day-sales" />

						<p class="pkongge"></p>

						<div class="cataleimu">店铺产品查询</div>
						<el-input v-model="form.productmonthsalebig" placeholder="产品月销大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.productmonthsalesmall" placeholder="产品月销小于" class="day-sales" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.productpricebig" placeholder="产品价格大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.productpricesmall" placeholder="产品价格小于" class="day-sales" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.productreviewbig" placeholder="产品评价大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.productreviewsmall" placeholder="产品评价小于" class="day-sales" />

						<p class="pkongge"></p>
						<el-input v-model="form.salesbig" placeholder="预估日销大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.salessmall" placeholder="预估日销小于" class="day-sales" />

						<span class="kongge-new"> </span>

						<el-select v-model="form.fbam" placeholder="发货模式" class="day-sales">
							<el-option label="发货模式" value="" />
							<el-option label="所有" value="all" />
							<el-option label="FBM" value="fbm" />
							<el-option label="FBA" value="Amazon" />
						</el-select>

						<span class="kongge-new"> </span>
						<el-select v-model="selected" filterable allow-create default-first-option placeholder="上架时间"
							@change="handleChangeInput" class="day-sales">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
						<!-- <el-select v-model="form.alltime" placeholder="上架时间" class="day-sales">
							<el-option label="上架时间" value="" />
							<el-option label="所有" value="all" />
							<el-option label="最近30天" value="30" />
							<el-option label="最近60天" value="60" />
							<el-option label="最近180天" value="180" />
							<el-option label="最近1年" value="365" />
						</el-select> -->
						<span class="kongge-new"> </span>
						<el-input v-model="form.searchtitle" placeholder="关键词" class="day-sales" />
						<!-- storename -->
						<span class="kongge-new"> </span>
						<el-input v-model="form.storename" placeholder="店铺名称" class="day-sales" />

						<p class="pkongge"></p>
						<el-input v-model="form.reviewstarbig" placeholder="评分大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.reviewstarsmall" placeholder="评分小于" class="day-sales" />

						<span class="kongge-new"> </span>

						<el-button type="primary" @click="searchdb" :loading="form.submit_button_loading">查询</el-button>
						<el-button type="warning"><a :href=formlink.results.dianpulink target="_blank"
								style="color: white;">使用视频教程</a></el-button>

						<el-button class="custom-color-button">为了更好地体验，请在使用前观看视频教程 !!!</el-button>

						<!-- <span class="kongge-new-more">为了更好地体验，请在使用前观看视频教程 !!!</span> -->

					</div>

				</div>

			</el-form>
		</div>
	</div>

	<p id="scroll-here" class="home-h3" v-if="store.state.user.isLogin == true">
	<h3>店铺查询结果</h3>
	</p>
	<!-- 显示搜索数据 -->
	<div class="search-content" v-if="store.state.user.isLogin == true">
		<div class="clear-fix">

			<div class="each-goods" v-for="item in form.results" :key="item.id">

				<div class="itemson fl">

					<div class="itemson-left fl">
						<a :href="'https://www.amazon.com/dp/' + item.asin + '?th=1&psc=1'" target="_blank"><img
								:src="item.imgsrc" alt=""></a>
					</div>

					<div class="itemson-right fl">
						<p class="detail-title">{{ item.title }}</p>
						<div class="detail-asin">
							<span class="detail-data"><span class="asin">ASIN</span>
								<a :href="'https://www.amazon.com/dp/' + item.asin + '?th=1&psc=1'" target="_blank"
									class="seedetail">{{ item.asin }}</a>
							</span>

							<span class="detail-data">评价: {{ item.review }}</span>
							<span class="detail-data">价格: {{ item.price }}</span>
							<span class="detail-data">评分: {{ item.reviewstar }}</span>

							<span class="detail-data-sales">父体预估日销: {{ item.sales }}</span>
							<span class="detail-data-monthsales">子体预估月销: {{ item.monthsales }}</span>
							<span class="detail-data-jingpin" v-if="item.result < 31">精品店铺</span>
							<span class="detail-data-jingpin" v-if="item.result > 30 && item.result < 200">精铺店铺</span>
							<span class="detail-data-jingpin" v-if="item.result > 200">铺货店铺</span>
							<span class="detail-data-store"><a :href="item.keywords" target="_blank"
									class="seedetail">查看店铺</a></span>

						</div>

						<!-- <div class="detail-asin-time">
							<span class="detail-data" v-if="item.begintime != '0'">上架时间: {{ item.begintime }}</span>
							<span class="detail-data">评价: {{ item.review }}</span>
							<span class="detail-data">价格: {{ item.price }}</span>
							<span class="detail-data">评分: {{ item.reviewstar }}</span>
							<span class="detail-data">发货模式: {{ item.fbam }}</span>
						</div> -->

						<div class="store">

							<div class="ptagup">
								<span class="detail-data">店铺所属国家: {{ item.storecountry }}</span>
								<span class="detail-data">店铺最近12个月反馈数: {{ item.storefeedback }}</span>
								<span class="detail-data">店铺产品数量: {{ item.result }}</span>
								<span class="detail-data">店铺整体评分: {{ item.storestar }} %</span>
								<span class="detail-data">店铺首页月销量总和: {{ item.allsales }} </span>
							</div>

							<div class="ptagdown">
								<div>
									<p class="ptagup">
										<span class="detail-data">首页平均评价: {{ item.avReview }}</span>
										<span class="detail-data">首页平均价格: {{ item.avPrice }}</span>
										<span class="detail-data">首页自发货数: {{ item.fbm }}</span>
										<span class="detail-data">发货模式: {{ item.fbam }}</span>
										<span class="detail-data" v-if="item.begintime != '0'">上架时间: {{ item.begintime
											}}</span>

										<a :href="'https://www.amazon.com/dp/' + item.asin + '?th=1&psc=1'"
											target="_blank" class="detailall">查看详情</a>

									</p>
								</div>
							</div>

						</div>

					</div>

				</div>
			</div>

		</div>

		<div>
			<span class="freeaccount-show" v-if="store.state.user.type == 0">免费用户, 每次搜索只能显示1页!</span>
		</div>



		<div class="pagination-block" v-if="allcount > 100">

			<el-pagination v-model:current-page="currentPage3" v-model:page-size="pageSize3" :small="small"
				:disabled="disabled" :background="background" layout="prev, pager, next, jumper" :total="allcount"
				@size-change="handleSizeChange" @current-change="handleCurrentChange" />
		</div>
	</div>
	<!-- form.submit_button_loading form.showloading -->
	<div class="data-loading" v-if="form.showloading == true">
		<el-button type="danger" class="loading-center" :loading="form.showloading">数据正在加载中, 请稍候...</el-button>
	</div>
</template>

<script setup>
import { onMounted, reactive, getCurrentInstance, ref } from 'vue'
import { getHomeProducRequest, postStoreProductRequest, getcourse } from "@/network/user.js";
import store from '@/store/index.js';

// TEST开始+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

import { ElSelect, ElOption } from 'element-plus'

// 示例选项数据
const options = ref([
	{ value: '100', label: '所有时间段' },
	{ value: '30', label: '最近30天' },
	{ value: '60', label: '最近60天' },
	{ value: '90', label: '最近90天' },
	{ value: '180', label: '最近180天' },
	{ value: '365', label: '最近1年' }
])

const selected = ref('')

// 处理选择改变的事件
const handleChangeInput = (value) => { }

// TEST结束++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 获取实例上下文
const { proxy } = getCurrentInstance();

// 定义翻页JS 开始-----------------------------

const currentPage3 = ref(1)
const pageSize3 = ref(50)
const small = ref(false)
const background = ref(false)
const disabled = ref(false)
const allcount = ref(0)

const form = reactive({

	allcheckbox: [],
	country: '',
	address: '',
	feedbackbig: '',
	feedbacksmall: '',
	storestarbig: '',
	storestarsmall: '',
	storesalesbig: '',
	storesalessmall: '',
	storearreviewbig: '',
	storearreviewsmall: '',
	storearrpricebig: '',
	storearrpricesmall: '',

	storeproductnumberbig: '',
	storeproductnumbersmall: '',
	storezifahuolvbig: '',
	storezifahuolvsmall: '',
	storelanhaisalesbig: '',
	storelanhaisalessmall: '',

	// 单个产品参数设置
	productmonthsalebig: '',
	productmonthsalesmall: '',
	productpricebig: '',
	productpricesmall: '',
	productreviewbig: '',
	productreviewsmall: '',
	// 产品发货模式
	fbam: '',
	submit_button_loading: false,
	// results: "", // results
	showloading: false,
	alltime: '',
	searchtitle: '',
	storename: '',
	salessmall: '',
	salesbig: '',
	// reviewstar大于小于
	reviewstarbig: '',
	reviewstarsmall: '',
})

// 只用于提取链接
const formlink = reactive({
	results: "",
	dianpulink: '',
	abakeywordslink: '',
	productlink: ''
})

const handleSizeChange = (val) => {
	let nothing = ''
}

// 定义翻页JS 开始-----------------------------
const handleCurrentChange = (val) => {

	let cata = ''

	if (form.allcheckbox) {
		for (let i = 0; i < form.allcheckbox.length; i++) {
			// console.log(form.allcheckbox[i]);
			if (form.allcheckbox[i].indexOf("Appliances") != -1) {
				cata = cata + 'Appliances,'
			}
			if (form.allcheckbox[i].indexOf("Arts") != -1) {
				cata = cata + 'Arts,'
			}
			if (form.allcheckbox[i].indexOf("Automotive") != -1) {
				cata = cata + 'Automotive,'
			}
			if (form.allcheckbox[i].indexOf("Baby") != -1) {
				cata = cata + 'Baby,'
			}
			if (form.allcheckbox[i].indexOf("Beauty") != -1) {
				cata = cata + 'Beauty,'
			}
			if (form.allcheckbox[i].indexOf("Photo") != -1) {
				cata = cata + 'Camera,'
			}
			if (form.allcheckbox[i].indexOf("Phones") != -1) {
				cata = cata + 'Cell,'
			}
			if (form.allcheckbox[i].indexOf("Clothing") != -1) {
				cata = cata + 'Clothing,'
			}
			if (form.allcheckbox[i].indexOf("Computers") != -1) {
				cata = cata + 'Computer,'
			}
			if (form.allcheckbox[i].indexOf("Electronic") != -1) {
				cata = cata + 'Electronic,'
			}
			if (form.allcheckbox[i].indexOf("Grocery") != -1) {
				cata = cata + 'Grocery,'
			}
			if (form.allcheckbox[i].indexOf("Handmade") != -1) {
				cata = cata + 'Handmade,'
			}
			if (form.allcheckbox[i].indexOf("Health") != -1) {
				cata = cata + 'Health,'
			}
			if (form.allcheckbox[i].indexOf("Home & Kitchen") != -1) {
				cata = cata + 'Home,'
			}
			if (form.allcheckbox[i].indexOf("Industrial") != -1) {
				cata = cata + 'Industrial,'
			}
			if (form.allcheckbox[i].indexOf("Dining") != -1) {
				cata = cata + 'Dining,'
			}
			if (form.allcheckbox[i].indexOf("Musical") != -1) {
				cata = cata + 'Musical,'
			}
			if (form.allcheckbox[i].indexOf("Office") != -1) {
				cata = cata + 'office,'
			}
			if (form.allcheckbox[i].indexOf("Patio") != -1) {
				cata = cata + 'Patio,'
			}
			if (form.allcheckbox[i].indexOf("Pet") != -1) {
				cata = cata + 'Pet,'
			}
			if (form.allcheckbox[i].indexOf("Outdoors") != -1) {
				cata = cata + 'Outdoors,'
			}
			if (form.allcheckbox[i].indexOf("Sports Collectibles") != -1) {
				cata = cata + 'Collectibles,'
			}
			if (form.allcheckbox[i].indexOf("Home Improvement") != -1) {
				cata = cata + 'Tools,'
			}
			if (form.allcheckbox[i].indexOf("Toys & Games") != -1) {
				cata = cata + 'Toys,'
			}
			if (form.allcheckbox[i].indexOf("Video Games") != -1) {
				cata = cata + 'Video,'
			}
		}
	}

	if (cata) {
		cata = cata.slice(0, -1);
	}

	// console.log('输入的参数', form)
	// console.log('店铺评分最大值:', form.storestarsmall)

	// const postdata = {

	// 	// monthsales_min: (form.sales1 > 0) ? form.sales1 : "1",
	// 	// monthsales_max: form.sales2,
	// 	catagorys: cata,
	// 	storecountry: form.country,
	// 	storeaddress: form.address,
	// 	storefeedback_min: form.feedbackbig,
	// 	storefeedback_max: form.feedbacksmall,
	// 	storestar_min: form.storestarbig,
	// 	storestar_max: form.storestarsmall,
	// 	allsales_min: form.storesalesbig,
	// 	allsales_max: form.storesalessmall,
	// 	avReview_min: form.storearreviewbig,
	// 	avReview_max: form.storearreviewsmall,
	// 	avPrice_min: form.storearrpricebig,
	// 	avPrice_max: form.storearrpricesmall,
	// 	// 店铺产品数量
	// 	result_min: form.storeproductnumberbig,
	// 	result_max: form.storeproductnumbersmall,
	// 	// 店铺自发货比率
	// 	avfbm_min: form.storezifahuolvbig,
	// 	avfbm_max: form.storezifahuolvsmall,
	// 	// 店铺蓝海月销平均值
	// 	lanhaisales_min: form.storelanhaisalesbig,
	// 	lanhaisales_max: form.storelanhaisalessmall,

	// 	// 产品月销
	// 	monthsales_min: form.productmonthsalebig,
	// 	monthsales_max: form.productmonthsalesmall,
	// 	// 产品价格
	// 	price_min: form.productpricebig,
	// 	price_max: form.productpricesmall,
	// 	// 产品评价
	// 	review_min: form.productreviewbig,
	// 	review_max: form.productreviewsmall,
	// 	fbam: (form.fbam.indexOf("all") != -1) ? '' : form.fbam,
	// 	// 上架时间
	// 	alltime_min: (form.alltime.indexOf("all") != -1) ? '' : '0',
	// 	alltime_max: (form.alltime.indexOf("all") != -1) ? '' : form.alltime
	// };

	const postdata = {

		catagorys: cata,
		storecountry: form.country,
		storeaddress: form.address,
		storefeedback_min: form.feedbackbig,
		storefeedback_max: form.feedbacksmall,
		storestar_min: form.storestarbig,
		storestar_max: form.storestarsmall,
		allsales_min: form.storesalesbig,
		allsales_max: form.storesalessmall,
		avReview_min: form.storearreviewbig,
		avReview_max: form.storearreviewsmall,
		avPrice_min: form.storearrpricebig,
		avPrice_max: form.storearrpricesmall,
		// 店铺产品数量
		result_min: form.storeproductnumberbig,
		result_max: form.storeproductnumbersmall,
		// 店铺自发货比率
		avfbm_min: form.storezifahuolvbig,
		avfbm_max: form.storezifahuolvsmall,
		// 店铺蓝海月销平均值
		lanhaisales_min: form.storelanhaisalesbig,
		lanhaisales_max: form.storelanhaisalessmall,

		// 产品月销
		monthsales_min: form.productmonthsalebig,
		monthsales_max: form.productmonthsalesmall,
		// 产品价格
		price_min: form.productpricebig,
		price_max: form.productpricesmall,
		// 产品评价
		review_min: form.productreviewbig,
		review_max: form.productreviewsmall,
		fbam: (form.fbam.indexOf("all") != -1) ? '' : form.fbam,
		// 上架时间
		alltime_min: (!selected.value) ? '' : '1',
		alltime_max: (selected.value.indexOf("100") != -1) ? '' : selected.value,
		title: form.searchtitle,
		storename: form.storename,
		sales_min: form.salesbig,
		sales_max: form.salessmall

	};

	form.showloading = true;

	postStoreProductRequest(postdata, val)
		.then(res => {

			form.showloading = false;

			try {

				if (res.data.status === 2000) {
					form.showloading = false;
					// console.log('res.status', res.data.results);
					form.results = res.data.results;
					allcount.value = res.data.count;
					window.scrollTo(0, 397);

					if (res.data.status === 2002) {

						proxy.$message({
							message: '有错误!!!',
							type: "error",
							time: 5000
						});

					}

				}

				if (res.status === 401) {
					form.showloading = false;
					proxy.$message({
						message: "登录后才可以查询!!",
						type: "error",
						time: 5000
					});

				}

			} catch (error) {
				// console.log('Res', res)
				form.showloading = false;
				proxy.$message({
					message: "其他错误!",
					type: "error",
				});

			}

		})

}
// 定义翻页JS 结束-----------------------------

// 提交查询
const searchdb = () => {

	if (store.state.user.isLogin == true) {

		currentPage3.value = 1

		let cata = ''

		if (form.allcheckbox) {
			for (let i = 0; i < form.allcheckbox.length; i++) {
				// console.log(form.allcheckbox[i]);
				if (form.allcheckbox[i].indexOf("Appliances") != -1) {
					cata = cata + 'Appliances,'
				}
				if (form.allcheckbox[i].indexOf("Arts") != -1) {
					cata = cata + 'Arts,'
				}
				if (form.allcheckbox[i].indexOf("Automotive") != -1) {
					cata = cata + 'Automotive,'
				}
				if (form.allcheckbox[i].indexOf("Baby") != -1) {
					cata = cata + 'Baby,'
				}
				if (form.allcheckbox[i].indexOf("Beauty") != -1) {
					cata = cata + 'Beauty,'
				}
				if (form.allcheckbox[i].indexOf("Photo") != -1) {
					cata = cata + 'Camera,'
				}
				if (form.allcheckbox[i].indexOf("Phones") != -1) {
					cata = cata + 'Cell,'
				}
				if (form.allcheckbox[i].indexOf("Clothing") != -1) {
					cata = cata + 'Clothing,'
				}
				if (form.allcheckbox[i].indexOf("Computers") != -1) {
					cata = cata + 'Computer,'
				}
				if (form.allcheckbox[i].indexOf("Electronic") != -1) {
					cata = cata + 'Electronic,'
				}
				if (form.allcheckbox[i].indexOf("Grocery") != -1) {
					cata = cata + 'Grocery,'
				}
				if (form.allcheckbox[i].indexOf("Handmade") != -1) {
					cata = cata + 'Handmade,'
				}
				if (form.allcheckbox[i].indexOf("Health") != -1) {
					cata = cata + 'Health,'
				}
				if (form.allcheckbox[i].indexOf("Home & Kitchen") != -1) {
					cata = cata + 'Home,'
				}
				if (form.allcheckbox[i].indexOf("Industrial") != -1) {
					cata = cata + 'Industrial,'
				}
				if (form.allcheckbox[i].indexOf("Dining") != -1) {
					cata = cata + 'Dining,'
				}
				if (form.allcheckbox[i].indexOf("Musical") != -1) {
					cata = cata + 'Musical,'
				}
				if (form.allcheckbox[i].indexOf("Office") != -1) {
					cata = cata + 'office,'
				}
				if (form.allcheckbox[i].indexOf("Patio") != -1) {
					cata = cata + 'Patio,'
				}
				if (form.allcheckbox[i].indexOf("Pet") != -1) {
					cata = cata + 'Pet,'
				}
				if (form.allcheckbox[i].indexOf("Outdoors") != -1) {
					cata = cata + 'Outdoors,'
				}
				if (form.allcheckbox[i].indexOf("Sports Collectibles") != -1) {
					cata = cata + 'Collectibles,'
				}
				if (form.allcheckbox[i].indexOf("Home Improvement") != -1) {
					cata = cata + 'Tools,'
				}
				if (form.allcheckbox[i].indexOf("Toys & Games") != -1) {
					cata = cata + 'Toys,'
				}
				if (form.allcheckbox[i].indexOf("Video Games") != -1) {
					cata = cata + 'Video,'
				}
			}
		}

		if (cata) {
			cata = cata.slice(0, -1);
		}

		// console.log('已经选中的上架时间:', selected.value)
		// console.log('上架时间:', form.searchtitle)

		const postdata = {

			catagorys: cata,
			storecountry: form.country,
			storeaddress: form.address,
			storefeedback_min: form.feedbackbig,
			storefeedback_max: form.feedbacksmall,
			storestar_min: form.storestarbig,
			storestar_max: form.storestarsmall,
			allsales_min: form.storesalesbig,
			allsales_max: form.storesalessmall,
			avReview_min: form.storearreviewbig,
			avReview_max: form.storearreviewsmall,
			avPrice_min: form.storearrpricebig,
			avPrice_max: form.storearrpricesmall,
			// 店铺产品数量
			result_min: form.storeproductnumberbig,
			result_max: form.storeproductnumbersmall,
			// 店铺自发货比率
			avfbm_min: form.storezifahuolvbig,
			avfbm_max: form.storezifahuolvsmall,
			// 店铺蓝海月销平均值
			lanhaisales_min: form.storelanhaisalesbig,
			lanhaisales_max: form.storelanhaisalessmall,

			// 产品月销
			monthsales_min: form.productmonthsalebig,
			monthsales_max: form.productmonthsalesmall,
			// 产品价格
			price_min: form.productpricebig,
			price_max: form.productpricesmall,
			// 产品评价
			review_min: form.productreviewbig,
			review_max: form.productreviewsmall,
			fbam: (form.fbam.indexOf("all") != -1) ? '' : form.fbam,
			// 上架时间
			alltime_min: (!selected.value) ? '' : '1',
			alltime_max: (selected.value.indexOf("100") != -1) ? '' : selected.value,
			title: form.searchtitle,
			storename: form.storename,
			sales_min: form.salesbig,
			sales_max: form.salessmall,
			reviewstar_min: form.reviewstarbig,
			reviewstar_max: form.reviewstarsmall

		};

		form.submit_button_loading = true;
		form.showloading = true;

		let val = 0
		postStoreProductRequest(postdata, val)
			.then(res => {

				form.submit_button_loading = false;
				form.showloading = false;

				// console.log('res.status', res)

				try {

					if (res.data.status === 2000) {

						form.submit_button_loading = false;
						form.showloading = false;

						form.results = res.data.results;
						allcount.value = res.data.count;
						// 将页面滚动到scroll-here这!!!!
						// window.location.hash = "#scroll-here";
						window.scrollTo(0, 397);

						if (res.data.status === 2002) {

							proxy.$message({
								message: res.data.data,
								type: "error",
								time: 5000
							});

						} else {
							proxy.$message({
								message: "请查看查询结果...",
								type: "success",
							});
						}

					}

					if (res.status.status === 401) {
						form.submit_button_loading = false;
						form.showloading = false;
						proxy.$message({
							message: "登录后才可以查询!!",
							type: "error",
							time: 5000
						});

					}

					if (res.data.status === 4002) {
						form.submit_button_loading = false;
						form.showloading = false;
						proxy.$message({
							message: res.data.data,
							type: "error",
							time: 15000
						});

					}

					if (res.data.status === 5002) {
						form.submit_button_loading = false;
						form.showloading = false;
						proxy.$message({
							message: "您没有权限使用ABA产品开发模块!!!",
							type: "error",
							time: 15000
						});

					}

				} catch (error) {
					// console.log('Res', res)
					try {
						if (res.status === 401) {
							form.submit_button_loading = false;
							form.showloading = false;
							proxy.$message({
								message: "登录后才可以查询!!",
								type: "error",
								time: 5000
							});
						} else {
							form.submit_button_loading = false;
							form.showloading = false;
							proxy.$message({
								message: "其他错误!!",
								type: "error",
							});
						}
					} catch (error) {
						form.submit_button_loading = false;
						form.showloading = false;
						proxy.$message({
							message: "其他错误!!",
							type: "error",
						});
					}
				}

			})

	} else {

		proxy.$message({
			message: "登录后才可以查询!!",
			type: "error",
			time: 10000
		});

	}
};


const getcourseinfo = () => {
	const postdata = {}
	getcourse(postdata)
		.then((res) => {

			try {

				// form.results = res.data.data
				formlink.results = res.data[0]

			} catch (error) {

				proxy.$message({
					message: "其他错误!",
					type: "error",
				});

			}
		})
}


onMounted(() => {

	getcourseinfo()

	if (store.state.user.isLogin == true) {
		//homeproducts()
	}
})

</script>


<style lang="less" scoped>
body {
	position: relative !important;
}


.data-loading {
	width: 15rem;
	padding: 0.8rem;
	box-sizing: border-box;
	position: fixed;
	left: 50%;
	margin-left: -10rem;
	top: 50%;
	transform: translateY(-50%);
	// background: #ecf5ff;
	z-index: 5000;
	border-radius: 15px;

	.loading-center {
		padding: 80px;
		font-size: 14px;
		font-weight: bold;
		color: #e6a23c;
		background-color: #fff;
	}
}

.el-input {
	--el-input-width: 0;
}

.content {

	width: var(--content-width);
	margin: 0 auto;
	background-color: aliceblue;
	// background-color: #c6e2ff;
	border-radius: 5px;
	padding: 15px;

	.itemson-left {
		width: 25%;

		.itemson-inner {
			background-color: white;
			padding: 15px 3px 20px 20px;
			border-radius: 5px;
		}

		h2 {
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 5px;
			color: #606266;
		}

		.itemson-left-cata {
			height: 249px;
			overflow-x: hidden;
			overflow-y: auto;
			font-size: 14px;

			.el-checkbox.el-checkbox--large {
				height: 30px;
			}

			// p{
			// 	margin-top: 10px;
			// 	padding-right: 20px;
			// }
		}
	}

	.itemson-right {
		width: 75%;

		.right-form {
			margin-left: 15px;
		}

		.day-sales {
			width: 15.5%;
		}

		.fbamz {
			width: 15.5%;
		}

		.storeaddress {
			width: 15.5%;
		}

		.from-time {
			width: 20%;
		}

		.keywords {
			width: 32.4%;
		}

		.nokeywords {
			width: 49.2%;
		}

		.lanhai-cata {
			width: 15%;
		}

		.search-title {
			width: 30%;
		}

		.lanhai-sales-cata {
			width: 16.5%;
		}

		.sale-jizhongdu {
			margin-top: 16px;
			// text-align: center;
		}

		.kongge-new {
			padding: 0 6px;
		}

		.kongge-new-more {

			padding-left: 15px;
			color: red;
			font-size: 14px;
		}

		.pkongge {
			padding: 7px 0;
		}

		.searchbutton {
			margin-top: 20px;
			// text-align: center;
		}

		.account-jifen {
			margin-left: 20px;
		}
	}

	.clear-fix::after {
		content: "";
		display: table;
		clear: both;
	}

	.cataleimu {
		text-align: center;
		margin: 14px 0;
		color: #606266;
	}

	.cataleimu::before {
		content: "------------------------------   ";
		color: #ccc;
	}

	.cataleimu::after {
		content: "   ------------------------------";
		color: #ccc;
	}
}


.home-h3 {
	padding: 20px;
	text-align: center;

	h3 {
		font-size: 16px;
		color: #606266;
	}
}

.home-h3 h3::before {
	content: "------------------------------   ";
	color: #cccccc;
	font-size: 12px;
	font-weight: normal;
}

.home-h3 h3::after {
	content: "   ------------------------------";
	color: #cccccc;
	font-size: 12px;
	font-weight: normal;
}

.search-content {

	width: var(--content-width);
	margin: 0 auto;
	background-color: #f5f5f5;
	border-radius: 5px;
	padding: 15px;

	.itemson {
		width: 100%;
		margin-bottom: 20px;

		.itemson-left {
			width: 25%;

			// background-color: #1477e1;
			img {
				padding-right: 20px;
				object-fit: contain;
				height: 135px;
				width: 250px;
				border-radius: 5px;
				background-color: white;
				padding: 15px;
			}
		}

		.itemson-right {
			width: 75%;
			height: 165px;
			border-radius: 5px;
			background-color: #fff;
			padding: 10px 15px;
			box-sizing: border-box;

			.detail-title {
				font-size: 14px;
				width: 60%;
				margin-bottom: 10px;
				height: 40px;
				overflow: hidden;
				line-height: 20px;
				text-overflow: ellipsis;
			}

			.detail-asin {
				font-size: 14px;
				margin-bottom: 15px;
			}

			.detail-asin-time {
				font-size: 14px;
				margin-top: 10px;
				margin-bottom: 10px;
			}

			.asin {
				border-radius: 3px;
				background-color: #ff9900;
				color: #ffffff;
				font-size: 12px;
				padding: 5px 10px;
				margin-right: 10px;
			}

			span.detail-data-sales {
				border-radius: 3px;
				background-color: #a0cfff;
				color: #000000;
				font-size: 12px;
				padding: 5px 10px;
				margin-right: 10px;
			}

			span.detail-data-monthsales {
				border-radius: 3px;
				background-color: #efefef;
				color: #000000;
				font-size: 12px;
				padding: 5px 10px;
				margin-right: 10px;
			}

			span.detail-data-jingpin {
				border-radius: 3px;
				background-color: red;
				color: #ffffff;
				font-size: 12px;
				padding: 5px 10px;
				margin-right: 10px;
			}

			span.detail-data-store {
				border-radius: 3px;
				background-color: #efefef;
				color: #000000;
				font-size: 12px;
				padding: 5px 10px;
			}

			span.detail-data {
				padding-right: 15px;
			}



			div.store {
				border-radius: 5px;
				background-color: #fafafa;
				padding: 10px;
				position: relative;

				.ptagdown {
					margin-top: 6px;
				}

				.detailall {
					font-size: 12px;
					background-color: #e6a23c;
					padding: 5px 10px;
					border-radius: 5px 0 5px 0;
					color: white;
					display: block;
					position: absolute;
					bottom: 0;
					right: 0;

				}
			}



		}

		// .itemson-left img {
		// 	padding-right: 20px;
		// 	object-fit: contain;
		// 	height: 150px;
		// 	width: 250px;
		// 	border-radius: 5px;
		// 	background-color: white;
		// 	padding: 15px;
		// }

		// .div-data-up {
		// 	background-color: white;
		// 	margin-right: 15px;
		// 	height: 90px;
		// 	border-radius: 5px 5px 0 0;
		// 	border: 1px solid #c6e2ff;

		// 	div {
		// 		padding: 10px;
		// 		padding-top: 0;
		// 	}
		// }

		// .div-data-bottom {
		// 	background-color: #c6e2ff;
		// 	margin-right: 15px;
		// 	height: 65px;
		// 	border-radius: 0 0 5px 5px;
		// 	// border: 1px solid #cccccc;
		// 	position: relative;

		// 	div {
		// 		padding: 10px;
		// 		padding-top: 0;
		// 	}

		// 	a.linkblue {
		// 		color: blue;
		// 	}

		// 	a.seedetail {
		// 		background-color: #e6a23c;
		// 		padding: 5px 10px;
		// 		border-radius: 5px 0 5px 0;
		// 		color: white;
		// 		display: block;
		// 		position: absolute;
		// 		bottom: 0;
		// 		right: 0;
		// 	}
		// }

		// .ptagup {
		// 	padding-top: 10px;

		// 	.detail-data {
		// 		padding-right: 10px;
		// 	}
		// }

	}

	.clear-fix::after {
		content: "";
		display: table;
		clear: both;
	}

	// ===============================================

	.cataleimu {
		margin: 20px 0;
		font-size: 12px;
		text-align: center;
	}

	.cataleimu::before {
		content: "------------------------------   ";
		color: #cccccc;
	}

	.cataleimu::after {
		content: "   ------------------------------";
		color: #cccccc;
	}

	.bottom-text {
		text-align: center;
		font-size: 16px;
		font-weight: bold;
	}
}

.account-showpage {
	color: red;
	margin-left: 15px;
}

.freeaccount-show {
	display: block;
	align-items: center;
	text-align: center;
}

.pagination-block {
	.el-pagination {
		display: flex;
		justify-content: center;
	}
}

.seedetail {
	color: #f90;
	/* 设置字体颜色为蓝色 */
	text-decoration: underline;
	/* 添加下划线 */
}

.custom-color-button {
	color: #FF5722;
	/* 自定义的字体颜色 */
}
</style>