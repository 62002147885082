import axios from 'axios';
import store from "@/store";
export function request(config) {
	const instance = axios.create({
		// baseURL:"http://127.0.0.1:8000/api/",
		// baseURL:process.env.VUE_APP_BASE_URL,
		baseURL: "https://www.topai123.com/api/",
		timeout: 200000,
	})

	// 请求拦截
	instance.interceptors.request.use(config => {
		// 请求头添加token
		const token = window.localStorage.getItem("token");
		if (token) {
			config.headers.Authorization = token;
		}

		return config;

	}, err => {
		// 错误信息
	})

	// 响应拦截
	instance.interceptors.response.use(res => {

		if (res.data.status == false) {
			window.localStorage.setItem("token", "");
			store.commit("setIsLogin", false);
		}

		return res
		// return res.data ? res.data : res;
	}, err => {
		// 响应错误401, 404, 500
		try {

			if (err.message.indexOf('401') != -1) {
				let res = { "status": 401 }
				return res
			}

			if (err.message.indexOf('429') != -1) {
				let res = { "status": 429 }
				return res
			}

		}
		
		catch (err) {
			let nothing = ''
		}


	})

	return instance(config);
} 