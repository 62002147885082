<template>
    <div class="content">

        <div class="clear-fix">

            <div class="each-goods" v-for="item in data.results" :key="item.id">

                <div class="itemson">
                    <div class="itemson-left">
                        <a :href="'https://www.amazon.com/dp/' + item.asin" target="_blank"><img :src="item.imgsrc"
                                alt=""></a>
                    </div>

                    <div class="itemson-detail">
                        <p><span class="detail-data">ASIN: <a :href="'https://www.amazon.com/dp/' + item.asin"
                                    target="_blank">{{ item.asin }}</a></span></p>
                        <p><span class="detail-data">价格: {{ item.price }}</span> / 预估月销: {{ item.monthsales }}</p>
                        <p><span class="detail-data">评价: {{ item.review }}</span> / 评分: {{ item.reviewstar }}</p>
                        <p class="detail-data-last"><span class="detail-data">发货模式: {{ item.fbam }}</span></p>
                        <p class="detail-data-last"><span class="detail-data">上架时间: {{ item.begintime }}</span></p>
                    </div>
                </div>

            </div>

            <div class="bottom-text">新品数据</div>

        </div>

    </div>
</template>

<script setup>

import { reactive, onMounted } from 'vue';
import { getHomeProducRequest } from "@/network/user.js";

const data = reactive({
    results: "", // results
})

const homeproducts = () => {
    const requestData = '';
    getHomeProducRequest(requestData)
        .then((response) => {

            try {

                data.results = response.data.results

            } catch (error) {

                proxy.$message({
                    message: "其他错误!",
                    type: "error",
                });

            }

        })
        .catch(() => {
            data.submit_button_loading = false;
        });
};

onMounted(() => {
    homeproducts()
})

</script>

<style lang="less" scoped>
.content {
    width: var(--content-width);
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 15px;

    // -----------------------特色他-------------------------

    .clear-fix {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 15px;
        justify-content: center;
        /* 添加这行代码 */
    }

    .each-goods {
        box-sizing: border-box;
    }

    .each-goods img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 5px;
        margin-bottom: 15px;
    }

    .bottom-text {
        text-align: center;
        grid-column: 1 / -1;
    }

    .itemson-detail{
        background-color: #ecf5ff;
        border-radius: 5px;
        padding: 10px;
    }

    .itemson-detail p {
        line-height: 1.6;
    }

    .itemson-detail a {
        color: #f90;
    }

    // ===============================================

    .cataleimu {
        margin: 20px 0;
        font-size: 12px;
        text-align: center;
    }

    .cataleimu::before {
        content: "------------------------------   ";
        color: #cccccc;
    }

    .cataleimu::after {
        content: "   ------------------------------";
        color: #cccccc;
    }

    .bottom-text {
        text-align: center;
    }
}
</style>