<template>
	<div class="content">
		<div class="clear-fix">
			<el-form ref="account_form" :model="form">

				<div class="itemson-left fl">
					<div class="itemson-inner">
						<h2>选择产品类目</h2>
						<div class="itemson-left-cata">
							<p><el-checkbox v-model="form.allcheckbox" label="家用电器 : Appliances" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="工艺 : Arts, Crafts & Sewing"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="汽配 : Automotive" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="母婴 : Baby" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="美妆 : Beauty & Personal Care"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="摄影 : Camera & Photo Products"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="3C电子 : Cell Phones & Accessories"
									size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="服装 : Clothing, Shoes & Jewelry"
									size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="电脑周边 : Computers & Accessories"
									size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="电子 : Electronics" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="食品 : Grocery & Gourmet Food"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="手工 : Handmade Products" size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="个护 : Health & Household" size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="家居 : Home & Kitchen" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="工业 : Industrial & Scientific"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="厨具 : Kitchen & Dining" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="乐器 : Musical Instruments" size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="办公用品 : Office Products" size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="庭院用品 : Patio, Lawn & Garden"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="宠物用品 : Pet Supplies" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="户外运动品 : Sports & Outdoors" size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="运动记念品 : Sports Collectibles"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="工具 : Tools & Home Improvement"
									size="large" />
							</p>
							<p><el-checkbox v-model="form.allcheckbox" label="玩具 : Toys & Games" size="large" /></p>
							<p><el-checkbox v-model="form.allcheckbox" label="视频游戏 : Video Games" size="large" /></p>
						</div>
					</div>
				</div>

				<div class="itemson-right fl">
					<div class="right-form">
						<el-input v-model="form.sales1" placeholder="日销大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.sales2" placeholder="日销小于" class="day-sales" />
						<span class="kongge-new"> </span>
						<el-input v-model="form.price1" placeholder="价格大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.price2" placeholder="价格小于" class="day-sales" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.review1" placeholder="评价大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.review2" placeholder="评价小于" class="day-sales" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.bigrank1" placeholder="排名大于" class="day-sales" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.bigrank2" placeholder="排名小于" class="day-sales" />

						<span class="kongge-new"> </span>
						<el-select v-model="form.fbam" placeholder="发货模式" class="fbamz">
							<el-option label="发货模式" value="" />
							<el-option label="所有" value="all" />
							<el-option label="FBM" value="fbm" />
							<el-option label="FBA" value="fba" />
							<el-option label="AMZ" value="amz" />
							<el-option label="断货" value="unavailable" />
						</el-select>
						<p class="pkongge"></p>
						<el-select v-model="form.datetime1" placeholder="上架时间" class="from-time">
							<el-option label="上架时间" value="" />
							<el-option label="所有" value="all" />
							<el-option label="最近30天" value="30" />
							<el-option label="最近60天" value="60" />
							<el-option label="最近180天" value="180" />
							<el-option label="最近1年" value="365" />
						</el-select>

						<span class="kongge-new"> </span>
						<el-input v-model="form.keywords" placeholder="产品关键词" class="keywords" />
						<span class="kongge-new"> </span>
						<el-input v-model="form.nokeywords" placeholder="否定关键词, 请用英文逗号隔开" class="nokeywords" />

						<div class="cataleimu">类目数据参数</div>
						<el-input v-model="form.catalanhaizhi1" placeholder="蓝海值大于" class="lanhai-cata" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.catalanhaizhi2" placeholder="蓝海值小于" class="lanhai-cata" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.catalanhaisale1" placeholder="蓝海平均日销大于" class="lanhai-sales-cata" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.catalanhaisale2" placeholder="蓝海平均日销小于" class="lanhai-sales-cata" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.catafbm1" placeholder="FBM大于%" class="lanhai-cata" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.catafbm2" placeholder="FBM小于%" class="lanhai-cata" />
						<p class="pkongge"></p>
						<el-input v-model="form.cataavgsale1" placeholder="类目平均日销大于" class="lanhai-cata" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.cataavgsale2" placeholder="类目平均日销小于" class="lanhai-cata" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.cataavgreview1" placeholder="类目平均评价大于" class="lanhai-sales-cata" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.cataavgreview2" placeholder="类目平均评价小于" class="lanhai-sales-cata" />

						<span class="kongge-new"> </span>
						<el-input v-model="form.catabrand1" placeholder="品牌集中度大于%" class="lanhai-cata" />
						<span class="text-gray-500"> - </span>
						<el-input v-model="form.catabrand2" placeholder="品牌集中度小于%" class="lanhai-cata" />

						<div class="sale-jizhongdu">
							<el-input v-model="form.catabuyer1" placeholder="卖家集中度大于%" class="lanhai-cata" />
							<span class="text-gray-500"> - </span>
							<el-input v-model="form.catabuyer2" placeholder="卖家集中度小于%" class="lanhai-cata" />

							<span class="kongge-new"> </span>
							<el-input v-model="form.catagoods1" placeholder="商品集中度大于%" class="lanhai-sales-cata" />
							<span class="text-gray-500"> - </span>
							<el-input v-model="form.catagoods2" placeholder="商品集中度小于%" class="lanhai-sales-cata" />

							<span class="kongge-new"> </span>
							<el-input v-model="form.cataavgrating1" placeholder="类目平均评分大于" class="lanhai-cata" />
							<span class="text-gray-500"> - </span>
							<el-input v-model="form.cataavgrating2" placeholder="类目平均评分小于" class="lanhai-cata" />
						</div>

						<div class="cataleimu">ABA关键词首页数据参数</div>

						<div class="sale-jizhongdu">
							<el-input v-model="form.allsales_min" placeholder="月销量大于" class="lanhai-cata" />
							<span class="text-gray-500"> - </span>
							<el-input v-model="form.allsales_max" placeholder="月销量小于" class="lanhai-cata" />

							<span class="kongge-new"> </span>
							<el-input v-model="form.lanhaisales_min" placeholder="蓝海月销量大于" class="lanhai-sales-cata" />
							<span class="text-gray-500"> - </span>
							<el-input v-model="form.lanhaisales_max" placeholder="蓝海月销量小于" class="lanhai-sales-cata" />

							<span class="kongge-new"> </span>
							<el-input v-model="form.arrlanhaisales_min" placeholder="蓝海月均销量大于" class="lanhai-cata" />
							<span class="text-gray-500"> - </span>
							<el-input v-model="form.arrlanhaisales_max" placeholder="蓝海月均销量小于" class="lanhai-cata" />
						</div>

						<div class="searchbutton">
							<el-button type="primary" @click="searchdb"
								:loading="form.submit_button_loading">查询</el-button>
							<el-button type="warning"><a :href=formlink.results.productlink target="_blank"
									style="color: white;">使用视频教程</a></el-button>
							<!-- 添加 v-if 只有登录的用户才显示 -->
							<span class="account-jifen" v-if="store.state.user.isLogin == true">每次查询数据会消耗1 AI豆!</span>
						</div>

					</div>
				</div>

			</el-form>
		</div>
	</div>

	<p id="scroll-here" class="home-h3" v-if="store.state.user.isLogin == true">
	<h3>产品查询结果</h3>
	</p>
	<!-- 显示搜索数据 -->
	<div class="search-content" v-if="store.state.user.isLogin == true">
		<div class="clear-fix">

			<div class="each-goods" v-for="item in form.results" :key="item.id">
				<div class="itemson fl">
					<div class="itemson-left fl">
						<a :href="'https://www.amazon.com/dp/' + item.cataasin" target="_blank"><img :src="item.img"
								alt=""></a>
					</div>

					<div class="itemson-right fl">
						<div class="div-data-up">
							<div>
								<p class="ptagup">
									<!-- <span class="detail-data">日销: {{ item.sales }}</span> -->
									<span class="detail-data">日销: {{ item.sales }}</span>
									<span class="detail-data">评价: {{ item.catareview }}</span>
									<!-- <span class="detail-data">大类排名: {{ item.bigrank }}</span> -->
									<span class="detail-data">评分: {{ item.rating }}</span>
									<span class="detail-data">价格: {{ item.cataprice }}</span>
								</p>
								<p class="ptagup">
									<!-- <span class="detail-data">评价: {{ item.catareview }}</span> -->
									<!-- <span class="detail-data">大类排名: {{ item.bigrank }}</span> -->
									<span class="detail-data">发货模式: {{ item.fahuo }}</span>
									<span class="detail-data">上架日期: {{ item.begintime }} </span>
									<span class="detail-data">类目: {{ item.bigcategory }} </span>
								</p>
							</div>
						</div>

						<div class="div-data-bottom">
							<div>
								<p class="ptagup">
									<span class="detail-data">类目蓝海值: {{ item.lanhaizhi }}</span>
									<span class="detail-data">蓝海平均日销: {{ item.newavgsales }}</span>
									<span class="detail-data">FBA/FBM/AMZ: {{ item.fba }}/{{ item.fbm }}/{{ item.amz
										}}</span>
								</p>
								<p class="ptagup">
									<span class="detail-data">平均评价: {{ item.avgreview }}</span>
									<span class="detail-data">平均日销: {{ item.avgsales }}</span>
									<span class="detail-data">平均价格: {{ item.avgprice }}</span>
									<!-- <span class="detail-data"><a :href="item.catalink" target="_blank"
											class="linkblue">类目链接</a></span> -->
								</p>

								<p class="ptagup">
									<span class="detail-data">品牌集中度: {{ item.brandrate }}</span>
									<span class="detail-data">销量集中度: {{ item.salerate }}</span>
									<span class="detail-data">商品集中度: {{ item.goodsrate }}</span>
									<a :href="'https://www.amazon.com/dp/' + item.cataasin" target="_blank"
										class="seedetail">查看详情</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<span class="freeaccount-show" v-if="store.state.user.type == 0">免费用户, 每次搜索只能显示1页!</span>

		</div>



		<div class="pagination-block" v-if="allcount > 20">

			<el-pagination v-model:current-page="currentPage3" v-model:page-size="pageSize3" :small="small"
				:disabled="disabled" :background="background" layout="prev, pager, next, jumper" :total="allcount"
				@size-change="handleSizeChange" @current-change="handleCurrentChange" />
		</div>
	</div>
	<!-- form.submit_button_loading form.showloading -->
	<div class="data-loading" v-if="form.showloading == true">
		<el-button type="danger" class="loading-center" :loading="form.showloading">数据正在加载中, 请稍候...</el-button>
	</div>
</template>

<script setup>
import { onMounted, reactive, getCurrentInstance, ref } from 'vue'
import { getHomeProducRequest, postNewProductRequest, getcourse, getNewProductRequest } from "@/network/user.js";
import store from '@/store/index.js';

// 获取实例上下文
const { proxy } = getCurrentInstance();

// 定义翻页JS 开始-----------------------------

const currentPage3 = ref(1)
const pageSize3 = ref(20)
const small = ref(false)
const background = ref(false)
const disabled = ref(false)
const allcount = ref(0)

const form = reactive({
	allcheckbox: [],
	sales1: '',
	sales2: '',
	price1: '',
	price2: '',
	review1: '',
	review2: '',
	weight1: '',
	weight2: '',
	bigrank1: '',
	bigrank2: '',
	fbam: '',
	datetime1: '',
	keywords: '',
	nokeywords: '',
	catalanhaizhi1: '',
	catalanhaizhi2: '',
	catalanhaisale1: '',
	catalanhaisale2: '',
	catafbm1: '',
	catafbm2: '',
	cataavgsale1: '',
	cataavgsale2: '',
	cataavgreview1: '',
	cataavgreview2: '',
	catabrand1: '',
	catabrand2: '',
	catabuyer1: '',
	catabuyer2: '',
	catagoods1: '',
	catagoods2: '',
	cataavgrating1: '',
	cataavgrating2: '',
	submit_button_loading: false,
	results: "", // results
	showloading: false,
	// allcount:"",
	allsales_min: '',
	allsales_max: '',
	lanhaisales_min: '',
	lanhaisales_max: '',
	arrlanhaisales_min: '',
	arrlanhaisales_max: ''

})

// 只用于提取链接
const formlink = reactive({
	results: "",
	dianpulink: '',
	abakeywordslink: '',
	productlink: ''
})

const handleSizeChange = (val) => {
	let nothing = ''
}

// 定义翻页JS 开始-----------------------------
const handleCurrentChange = (val) => {

	let cata = ''

	if (form.allcheckbox) {
		for (let i = 0; i < form.allcheckbox.length; i++) {
			// console.log(form.allcheckbox[i]);
			if (form.allcheckbox[i].indexOf("Appliances") != -1) {
				cata = cata + 'Appliances,'
			}
			if (form.allcheckbox[i].indexOf("Arts") != -1) {
				cata = cata + 'Arts,'
			}
			if (form.allcheckbox[i].indexOf("Automotive") != -1) {
				cata = cata + 'Automotive,'
			}
			if (form.allcheckbox[i].indexOf("Baby") != -1) {
				cata = cata + 'Baby,'
			}
			if (form.allcheckbox[i].indexOf("Beauty") != -1) {
				cata = cata + 'Beauty,'
			}
			if (form.allcheckbox[i].indexOf("Photo") != -1) {
				cata = cata + 'Camera,'
			}
			if (form.allcheckbox[i].indexOf("Phones") != -1) {
				cata = cata + 'Cell,'
			}
			if (form.allcheckbox[i].indexOf("Clothing") != -1) {
				cata = cata + 'Clothing,'
			}
			if (form.allcheckbox[i].indexOf("Computers") != -1) {
				cata = cata + 'Computer,'
			}
			if (form.allcheckbox[i].indexOf("Electronic") != -1) {
				cata = cata + 'Electronic,'
			}
			if (form.allcheckbox[i].indexOf("Grocery") != -1) {
				cata = cata + 'Grocery,'
			}
			if (form.allcheckbox[i].indexOf("Handmade") != -1) {
				cata = cata + 'Handmade,'
			}
			if (form.allcheckbox[i].indexOf("Health") != -1) {
				cata = cata + 'Health,'
			}
			if (form.allcheckbox[i].indexOf("Home & Kitchen") != -1) {
				cata = cata + 'Home,'
			}
			if (form.allcheckbox[i].indexOf("Industrial") != -1) {
				cata = cata + 'Industrial,'
			}
			if (form.allcheckbox[i].indexOf("Dining") != -1) {
				cata = cata + 'Dining,'
			}
			if (form.allcheckbox[i].indexOf("Musical") != -1) {
				cata = cata + 'Musical,'
			}
			if (form.allcheckbox[i].indexOf("Office") != -1) {
				cata = cata + 'office,'
			}
			if (form.allcheckbox[i].indexOf("Patio") != -1) {
				cata = cata + 'Patio,'
			}
			if (form.allcheckbox[i].indexOf("Pet") != -1) {
				cata = cata + 'Pet,'
			}
			if (form.allcheckbox[i].indexOf("Outdoors") != -1) {
				cata = cata + 'Outdoors,'
			}
			if (form.allcheckbox[i].indexOf("Sports Collectibles") != -1) {
				cata = cata + 'Collectibles,'
			}
			if (form.allcheckbox[i].indexOf("Home Improvement") != -1) {
				cata = cata + 'Tools,'
			}
			if (form.allcheckbox[i].indexOf("Toys & Games") != -1) {
				cata = cata + 'Toys,'
			}
			if (form.allcheckbox[i].indexOf("Video Games") != -1) {
				cata = cata + 'Video,'
			}
		}
	}

	if (cata) {
		cata = cata.slice(0, -1);
	}

	const postdata = {
		bigcategory: cata,
		sales_min: form.sales1,
		sales_max: form.sales2,
		cataprice_min: form.price1,
		cataprice_max: form.price2,
		catareview_min: form.review1,
		catareview_max: form.review2,
		bigrank_min: form.bigrank1,
		bigrank_max: form.bigrank2,
		fahuo: (form.fbam.indexOf("all") != -1) ? '' : form.fbam,
		alltime_max: (form.datetime1.indexOf("all") != -1) ? '' : form.datetime1,
		alltime_min: (form.datetime1.indexOf("all") != -1) ? '' : '0',
		search: form.keywords, //包含关键词
		title: form.nokeywords, //否定词
		lanhaizhi_min: form.catalanhaizhi1,
		lanhaizhi_max: form.catalanhaizhi2,
		newavgsales_min: form.catalanhaisale1,
		newavgsales_max: form.catalanhaisale2,
		fbm_min: form.catafbm1,
		fbm_max: form.catafbm2,
		avgsales_min: form.cataavgsale1,
		avgsales_max: form.cataavgsale2,
		avgreview_min: form.cataavgreview1,
		avgreview_max: form.cataavgreview2,
		brandrate_min: form.catabrand1,
		brandrate_max: form.catabrand2,
		salerate_min: form.catabuyer1,
		salerate_max: form.catabuyer2,
		goodsrate_min: form.catagoods1,
		goodsrate_max: form.catagoods2,
		avgrating_min: form.cataavgrating1,
		avgrating_max: form.cataavgrating2,

		allsales_min: form.allsales_min,
		allsales_max: form.allsales_max,
		lanhaisales_min: form.lanhaisales_min,
		lanhaisales_max: form.lanhaisales_max,
		arrlanhaisales_min: form.arrlanhaisales_min,
		arrlanhaisales_max: form.arrlanhaisales_max
	};

	form.showloading = true;

	postNewProductRequest(postdata, val)
		.then(res => {

			form.showloading = false;

			try {

				if (res.data.status === 2000) {
					form.showloading = false;
					// console.log('res.status', res.data.results);
					form.results = res.data.results;
					allcount.value = res.data.count;
					window.scrollTo(0, 450);

					if (res.data.status === 2002) {

						proxy.$message({
							message: '有错误!!!',
							type: "error",
							time: 5000
						});

					}

				}

				if (res.status === 401) {
					form.showloading = false;
					proxy.$message({
						message: "登录后才可以查询!!",
						type: "error",
						time: 5000
					});

				}

			} catch (error) {
				// console.log('Res', res)
				form.showloading = false;
				proxy.$message({
					message: "其他错误!",
					type: "error",
				});

			}

		})

}
// 定义翻页JS 结束-----------------------------

// 提交查询
const searchdb = () => {

	if (store.state.user.isLogin == true) {

		currentPage3.value = 1
		let cata = ''

		if (form.allcheckbox) {
			for (let i = 0; i < form.allcheckbox.length; i++) {
				// console.log(form.allcheckbox[i]);
				if (form.allcheckbox[i].indexOf("Appliances") != -1) {
					cata = cata + 'Appliances,'
				}
				if (form.allcheckbox[i].indexOf("Arts") != -1) {
					cata = cata + 'Arts,'
				}
				if (form.allcheckbox[i].indexOf("Automotive") != -1) {
					cata = cata + 'Automotive,'
				}
				if (form.allcheckbox[i].indexOf("Baby") != -1) {
					cata = cata + 'Baby,'
				}
				if (form.allcheckbox[i].indexOf("Beauty") != -1) {
					cata = cata + 'Beauty,'
				}
				if (form.allcheckbox[i].indexOf("Photo") != -1) {
					cata = cata + 'Camera,'
				}
				if (form.allcheckbox[i].indexOf("Phones") != -1) {
					cata = cata + 'Cell,'
				}
				if (form.allcheckbox[i].indexOf("Clothing") != -1) {
					cata = cata + 'Clothing,'
				}
				if (form.allcheckbox[i].indexOf("Computers") != -1) {
					cata = cata + 'Computer,'
				}
				if (form.allcheckbox[i].indexOf("Electronic") != -1) {
					cata = cata + 'Electronic,'
				}
				if (form.allcheckbox[i].indexOf("Grocery") != -1) {
					cata = cata + 'Grocery,'
				}
				if (form.allcheckbox[i].indexOf("Handmade") != -1) {
					cata = cata + 'Handmade,'
				}
				if (form.allcheckbox[i].indexOf("Health") != -1) {
					cata = cata + 'Health,'
				}
				if (form.allcheckbox[i].indexOf("Home & Kitchen") != -1) {
					cata = cata + 'Home,'
				}
				if (form.allcheckbox[i].indexOf("Industrial") != -1) {
					cata = cata + 'Tools,'
				}
				if (form.allcheckbox[i].indexOf("Dining") != -1) {
					cata = cata + 'Dining,'
				}
				if (form.allcheckbox[i].indexOf("Musical") != -1) {
					cata = cata + 'Musical,'
				}
				if (form.allcheckbox[i].indexOf("Office") != -1) {
					cata = cata + 'office,'
				}
				if (form.allcheckbox[i].indexOf("Patio") != -1) {
					cata = cata + 'Patio,'
				}
				if (form.allcheckbox[i].indexOf("Pet") != -1) {
					cata = cata + 'Pet,'
				}
				if (form.allcheckbox[i].indexOf("Outdoors") != -1) {
					cata = cata + 'Outdoors,'
				}
				if (form.allcheckbox[i].indexOf("Sports Collectibles") != -1) {
					cata = cata + 'Collectibles,'
				}
				if (form.allcheckbox[i].indexOf("Home Improvement") != -1) {
					cata = cata + 'Tools,'
				}
				if (form.allcheckbox[i].indexOf("Toys & Games") != -1) {
					cata = cata + 'Toys,'
				}
				if (form.allcheckbox[i].indexOf("Video Games") != -1) {
					cata = cata + 'Video,'
				}
			}
		}

		if (cata) {
			cata = cata.slice(0, -1);
		}

		// console.log('选择当前的时间:', form.datetime1)

		const postdata = {
			bigcategory: cata,
			sales_min: form.sales1,
			sales_max: form.sales2,
			cataprice_min: form.price1,
			cataprice_max: form.price2,
			catareview_min: form.review1,
			catareview_max: form.review2,
			bigrank_min: form.bigrank1,
			bigrank_max: form.bigrank2,
			fahuo: (form.fbam.indexOf("all") != -1) ? '' : form.fbam,
			alltime_min: (form.datetime1.indexOf("all") != -1) ? '' : '0',
			alltime_max: (form.datetime1.indexOf("all") != -1) ? '' : form.datetime1,
			search: form.keywords, //包含关键词
			title: form.nokeywords, //否定词
			lanhaizhi_min: form.catalanhaizhi1,
			lanhaizhi_max: form.catalanhaizhi2,
			newavgsales_min: form.catalanhaisale1,
			newavgsales_max: form.catalanhaisale2,
			fbm_min: form.catafbm1,
			fbm_max: form.catafbm2,
			avgsales_min: form.cataavgsale1,
			avgsales_max: form.cataavgsale2,
			avgreview_min: form.cataavgreview1,
			avgreview_max: form.cataavgreview2,
			brandrate_min: form.catabrand1,
			brandrate_max: form.catabrand2,
			salerate_min: form.catabuyer1,
			salerate_max: form.catabuyer2,
			goodsrate_min: form.catagoods1,
			goodsrate_max: form.catagoods2,
			avgrating_min: form.cataavgrating1,
			avgrating_max: form.cataavgrating2,

			allsales_min: form.allsales_min,
			allsales_max: form.allsales_max,
			lanhaisales_min: form.lanhaisales_min,
			lanhaisales_max: form.lanhaisales_max,
			arrlanhaisales_min: form.arrlanhaisales_min,
			arrlanhaisales_max: form.arrlanhaisales_max
		};
		// postdata.fahuo
		form.submit_button_loading = true;
		form.showloading = true;

		let val = 0
		// let urlnew = '?search=yoga'
		// getNewProductRequest(urlnew)
		// 	.then(res => {
		// 		console.log('res.status', res)
		// 	})

		postNewProductRequest(postdata, val)
			.then(res => {

				form.submit_button_loading = false;
				form.showloading = false;
				// console.log('res.status', res)
				try {

					if (res.data.status === 2000) {

						form.submit_button_loading = false;
						form.showloading = false;

						form.results = res.data.results;
						allcount.value = res.data.count;
						// 将页面滚动到scroll-here这!!!!
						// window.location.hash = "#scroll-here";
						window.scrollTo(0, 450);

						if (res.data.status === 2002) {

							proxy.$message({
								message: res.data.data,
								type: "error",
								time: 5000
							});

						} else {
							proxy.$message({
								message: "请查看查询结果...",
								type: "success",
							});
						}

					}

					if (res.status.status === 401) {
						form.submit_button_loading = false;
						form.showloading = false;
						proxy.$message({
							message: "登录后才可以查询!!",
							type: "error",
							time: 5000
						});

					}

					if (res.data.status === 4002) {
						form.submit_button_loading = false;
						form.showloading = false;
						proxy.$message({
							message: "请检测您输入的条件是否正确!",
							type: "error",
							time: 15000
						});

					}

				} catch (error) {
					// console.log('Res', res)
					try {
						if (res.status === 401) {
							form.submit_button_loading = false;
							form.showloading = false;
							proxy.$message({
								message: "登录后才可以查询!!",
								type: "error",
								time: 5000
							});
						} else {
							form.submit_button_loading = false;
							form.showloading = false;
							proxy.$message({
								message: "其他错误!!",
								type: "error",
							});
						}
					} catch (error) {
						form.submit_button_loading = false;
						form.showloading = false;
						proxy.$message({
							message: "其他错误!!",
							type: "error",
						});
					}
				}

			})

	} else {

		proxy.$message({
			message: "登录后才可以查询!!",
			type: "error",
			time: 10000
		});

	}
};

const homeproducts = () => {

	let cata = ''

	if (form.allcheckbox) {
		for (let i = 0; i < form.allcheckbox.length; i++) {
			// console.log(form.allcheckbox[i]);
			if (form.allcheckbox[i].indexOf("Appliances") != -1) {
				cata = cata + 'appliances,'
			}
			if (form.allcheckbox[i].indexOf("Arts") != -1) {
				cata = cata + 'arts,'
			}
			if (form.allcheckbox[i].indexOf("Automotive") != -1) {
				cata = cata + 'automotive,'
			}
			if (form.allcheckbox[i].indexOf("Baby") != -1) {
				cata = cata + 'baby,'
			}
			if (form.allcheckbox[i].indexOf("Beauty") != -1) {
				cata = cata + 'beauty,'
			}
			if (form.allcheckbox[i].indexOf("Photo") != -1) {
				cata = cata + 'Camera,'
			}
			if (form.allcheckbox[i].indexOf("Phones") != -1) {
				cata = cata + 'Cell,'
			}
			if (form.allcheckbox[i].indexOf("Clothing") != -1) {
				cata = cata + 'Clothing,'
			}
			if (form.allcheckbox[i].indexOf("Computers") != -1) {
				cata = cata + 'computer,'
			}
			if (form.allcheckbox[i].indexOf("Electronic") != -1) {
				cata = cata + 'electronic,'
			}
			if (form.allcheckbox[i].indexOf("Grocery") != -1) {
				cata = cata + 'grocery,'
			}
			if (form.allcheckbox[i].indexOf("Handmade") != -1) {
				cata = cata + 'handmade,'
			}
			if (form.allcheckbox[i].indexOf("Health") != -1) {
				cata = cata + 'health,'
			}
			if (form.allcheckbox[i].indexOf("Home & Kitchen") != -1) {
				cata = cata + 'Home,'
			}
			if (form.allcheckbox[i].indexOf("Industrial") != -1) {
				cata = cata + 'industrial,'
			}
			if (form.allcheckbox[i].indexOf("Dining") != -1) {
				cata = cata + 'Dining,'
			}
			if (form.allcheckbox[i].indexOf("Musical") != -1) {
				cata = cata + 'Musical,'
			}
			if (form.allcheckbox[i].indexOf("Office") != -1) {
				cata = cata + 'office,'
			}
			if (form.allcheckbox[i].indexOf("Patio") != -1) {
				cata = cata + 'Patio,'
			}
			if (form.allcheckbox[i].indexOf("Pet") != -1) {
				cata = cata + 'pet,'
			}
			if (form.allcheckbox[i].indexOf("Outdoors") != -1) {
				cata = cata + 'Outdoors,'
			}
			if (form.allcheckbox[i].indexOf("Sports Collectibles") != -1) {
				cata = cata + 'collectibles,'
			}
			if (form.allcheckbox[i].indexOf("Home Improvement") != -1) {
				cata = cata + 'Tools,'
			}
			if (form.allcheckbox[i].indexOf("Toys & Games") != -1) {
				cata = cata + 'toys,'
			}
			if (form.allcheckbox[i].indexOf("Video Games") != -1) {
				cata = cata + 'Video,'
			}
		}
	}

	if (cata) {
		cata = cata.slice(0, -1);
	}

	const postdata = {
		bigcategory: cata,
		sales_min: form.sales1,
		sales_max: form.sales2,
		cataprice_min: form.price1,
		cataprice_max: form.price2,
		catareview_min: form.review1,
		catareview_max: form.review2,
		bigrank_min: form.bigrank1,
		bigrank_max: form.bigrank2,
		fahuo: (form.fbam.indexOf("all") != -1) ? '' : form.fbam,
		alltime_max: (form.datetime1.indexOf("all") != -1) ? '' : form.datetime1,
		search: form.keywords, //包含关键词
		title: form.nokeywords, //否定词
		lanhaizhi_min: form.catalanhaizhi1,
		lanhaizhi_max: form.catalanhaizhi2,
		newavgsales_min: form.catalanhaisale1,
		newavgsales_max: form.catalanhaisale2,
		fbm_min: form.catafbm1,
		fbm_max: form.catafbm2,
		avgsales_min: form.cataavgsale1,
		avgsales_max: form.cataavgsale2,
		avgreview_min: form.cataavgreview1,
		avgreview_max: form.cataavgreview2,
		brandrate_min: form.catabrand1,
		brandrate_max: form.catabrand2,
		salerate_min: form.catabuyer1,
		salerate_max: form.catabuyer2,
		goodsrate_min: form.catagoods1,
		goodsrate_max: form.catagoods2,
		avgrating_min: form.cataavgrating1,
		avgrating_max: form.cataavgrating2
	};

	const detailurl = ''
	getHomeProducRequest(detailurl)
		.then((res) => {

			try {
				form.submit_button_loading = false;
				// console.log('res.status', res)
				form.results = res.data.results
				allcount.value = res.data.count;

			} catch (error) {

				proxy.$message({
					message: "其他错误!",
					type: "error",
				});

			}

		})
		.catch(() => {
			form.submit_button_loading = false;
		});
};

const getcourseinfo = () => {
	const postdata = {}
	getcourse(postdata)
		.then((res) => {

			try {

				// form.results = res.data.data
				formlink.results = res.data[0]

			} catch (error) {

				proxy.$message({
					message: "其他错误!",
					type: "error",
				});

			}
		})
}

onMounted(() => {

	getcourseinfo()

	if (store.state.user.isLogin == true) {
		// homeproducts()
	}
})

</script>


<style lang="less" scoped>
body {
	position: relative !important;
}


.data-loading {
	width: 15rem;
	padding: 0.8rem;
	box-sizing: border-box;
	position: fixed;
	left: 50%;
	margin-left: -10rem;
	top: 50%;
	transform: translateY(-50%);
	// background: #ecf5ff;
	z-index: 5000;
	border-radius: 15px;

	.loading-center {
		padding: 80px;
		font-size: 14px;
		font-weight: bold;
		color: #e6a23c;
		background-color: #fff;
	}
}

.el-input {
	--el-input-width: 0;
}

.content {

	width: var(--content-width);
	margin: 0 auto;
	background-color: aliceblue;
	// background-color: #c6e2ff;
	border-radius: 5px;
	padding: 15px;

	.itemson-left {
		width: 25%;

		.itemson-inner {
			background-color: white;
			padding: 15px 3px 20px 20px;
			border-radius: 5px;
		}

		h2 {
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 5px;
			color: #606266;
		}

		.itemson-left-cata {
			height: 308px;
			overflow-x: hidden;
			overflow-y: auto;
			font-size: 14px;

			.el-checkbox.el-checkbox--large {
				height: 30px;
			}
		}
	}

	.itemson-right {
		width: 75%;

		.right-form {
			margin-left: 15px;
		}

		.day-sales {
			width: 9%;
		}

		.fbamz {
			width: 16.8%;
		}

		.from-time {
			width: 20%;
		}

		.keywords {
			width: 38%;
		}

		.nokeywords {
			width: 39%;
		}

		.lanhai-cata {
			width: 15%;
		}

		.lanhai-sales-cata {
			width: 16.5%;
		}

		.sale-jizhongdu {
			margin-top: 16px;
			// text-align: center;
		}

		.kongge-new {
			padding: 0 6px;
		}

		.pkongge {
			padding: 7px 0;
		}

		.searchbutton {
			margin-top: 20px;
			// text-align: center;
		}

		.account-jifen {
			margin-left: 20px;
		}
	}

	.clear-fix::after {
		content: "";
		display: table;
		clear: both;
	}

	.cataleimu {
		text-align: center;
		margin: 10px 0;
		color: #606266;
	}

	.cataleimu::before {
		content: "------------------------------   ";
		color: #ccc;
	}

	.cataleimu::after {
		content: "   ------------------------------";
		color: #ccc;
	}
}


.home-h3 {
	padding: 20px;
	text-align: center;

	h3 {
		font-size: 16px;
		color: #606266;
	}
}

.home-h3 h3::before {
	content: "------------------------------   ";
	color: #cccccc;
	font-size: 12px;
	font-weight: normal;
}

.home-h3 h3::after {
	content: "   ------------------------------";
	color: #cccccc;
	font-size: 12px;
	font-weight: normal;
}

.search-content {

	width: var(--content-width);
	margin: 0 auto;
	background-color: #f5f5f5;
	border-radius: 5px;
	padding: 15px;
	// margin-top: 20px;



	// .each-goods:nth-child(odd)>.itemson>.itemson-right{

	// }

	.each-goods:nth-child(even)>.itemson>.itemson-right>.div-data-up {
		margin-right: 0;
	}

	.each-goods:nth-child(even)>.itemson>.itemson-right>.div-data-bottom {
		margin-right: 0;
	}

	.itemson {
		width: 50%;
		margin-bottom: 20px;

		.itemson-left {
			width: 25%;
		}

		.itemson-right {
			width: 75%;
		}

		.itemson-left img {
			object-fit: contain;
			height: 145px;
			width: 120px;
			border-radius: 5px;
			background-color: white;
			padding: 5px;
		}

		.div-data-up {
			background-color: white;
			margin-right: 15px;
			height: 63px;
			border-radius: 5px 5px 0 0;
			border: 1px solid #c6e2ff;

			div {
				padding: 10px;
				padding-top: 0;
			}
		}

		.div-data-bottom {
			background-color: #c6e2ff;
			margin-right: 15px;
			height: 90px;
			border-radius: 0 0 5px 5px;
			// border: 1px solid #cccccc;
			position: relative;

			div {
				padding: 10px;
				padding-top: 0;
			}

			a.linkblue {
				color: blue;
			}

			a.seedetail {
				background-color: #e6a23c;
				padding: 5px 10px;
				border-radius: 5px 0 5px 0;
				color: white;
				display: block;
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}

		.ptagup {
			padding-top: 10px;

			.detail-data {
				padding-right: 10px;
			}
		}

	}

	.clear-fix::after {
		content: "";
		display: table;
		clear: both;
	}

	// ===============================================

	.cataleimu {
		margin: 20px 0;
		font-size: 12px;
		text-align: center;
	}

	.cataleimu::before {
		content: "------------------------------   ";
		color: #cccccc;
	}

	.cataleimu::after {
		content: "   ------------------------------";
		color: #cccccc;
	}

	.bottom-text {
		text-align: center;
		font-size: 16px;
		font-weight: bold;
	}
}

.account-showpage {
	color: red;
	margin-left: 15px;
}

.freeaccount-show {
	display: block;
	align-items: center;
	text-align: center;
}

.pagination-block {
	.el-pagination {
		display: flex;
		justify-content: center;
	}
}
</style>