<template>
	<div class="main clearfix">
		<div class="content">
			<div class="logo fl">
				<span class="toplogo">
					<span class="logohi">
						<el-icon>
							<Histogram />
						</el-icon>
					</span>
					<span class="topai123">TOPAi123</span></span>
			</div>
			<div class="left fl">
				<a href="/">首页</a>
				<a href="/abafree">热词榜</a>
				<a href="/storedata">店铺选品</a>
				<a href="/product">产品库</a>
				<!-- <a href="/abaonly">产品库</a> -->
				<a href="/abakw">ABA蓝海词</a>
				<!-- <a href="/abaproduct">关键词产品开发</a> -->
				<a href="/course">使用教程</a>
				<!-- <a href="/follow">价格</a> -->
				<!-- <a href="https://mp.weixin.qq.com/s/VCgHLlWNBbtbKLsK3q4XKQ" target="_blank">选品/广告优化课程</a> -->
			</div>
			<div class="right fr">
				<span class="loginre" v-if="store.state.user.isLogin == false">
				<!-- <span class="loginre" v-if="form.isLoginorNo == false"> -->
					<a href="/account">注册</a>
					<a href="/account">/登录</a>
				</span>
				<span class="loginre" v-else>
				<!-- <span class="loginre"> -->
					<a href="/profile">用户中心 | </a>
					<a href="#" @click="logout">退出</a>
				</span>
			</div>
		</div>
	</div>
</template>

<script setup>
import Logo from "@/components/common/Logo"
import { reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import store from '@/store/index.js'

const storeN = useStore();
const router = useRouter();

const logout = () => {
	window.localStorage.setItem("token", "");
	storeN.commit("setIsLogin", false);
	router.push("/")
}

const form = reactive({
	isLoginorNo: false,
})

if (store.state.user.isLogin == true) {
	form.isLoginorNo = true
}

</script>

<style lang="less" scoped>
.main {

	position: fixed;
	z-index: 1000;
	width: 100%;
	background-color: white;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	.content {
		width: var(--content-width);
		margin: 0 auto;
		height: 60px;
		// border: 1px solid red;
		line-height: 60px;
		font-size: 16px;

		.logo {
			margin-right: 250px;
		}

		.toplogo {
			font-size: 20px;
			font-weight: bold;
			border-radius: 5px;
			padding: 5px 15px;
			position: relative;

			span.logohi {
				color: #ff9900;
				font-size: 40px;
				height: 30px;
				line-height: 30px;
				position: absolute;
				top: -1px;
			}

			span.topai123 {
				position: absolute;
				left: 60px;
				font-size: 25px;
			}
		}

		.left {
			a {
				padding: 0 20px;
			}
		}

		.right {
			font-size: 14px;

			.loginre {
				border: 1px solid #ff9900;
				border-radius: 5px;
				padding: 8px 15px;

				a {
					color: #ff9900;
				}
			}
		}
	}

}
</style>