<template>
	<div class="main">
		<div class="content">
			<div class="logo fl">
				<span class="toplogo"><span class="logohi"><el-icon>
							<Histogram />
						</el-icon></span><span class="topai123">TOPAi123</span></span>
			</div>

			<div class="left fl">
				<a href="/">首页</a>
				<a href="/abafree">热词榜</a>
				<a href="/storedata">店铺选品</a>
				<a href="/abaonly">产品库</a>
				<a href="/abakw">ABA蓝海词</a>
				<!-- <a href="/abaproduct">关键词产品开发</a> -->
				<a href="/course">使用教程</a>
				<!-- <a href="/">非侵权品</a> -->
				
				<p> 版权所有© 2020-2024 TOPAI123.COM</p>
			</div>

			<div class="right fr">
				<p><h2>联系方式</h2></p>
				<p class="address">微信: ccaidata 电话: 137 1157 9919</p>
				<!-- <p>地址: 深圳市龙岗区</p> -->
			</div>
		</div>
	</div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.main {

	margin-top: 20px;
	width: 100%;
	background-color: #f5f5f5;

	.content {
		width: var(--content-width);
		margin: 0 auto;
		height: 60px;
		padding-top: 20px;
	}

	.logo {
			margin-right: 250px;
		}

	.toplogo {
			font-size: 14px;
			font-weight: bold;
			border-radius: 5px;
			padding: 5px 15px;
			position: relative;

			span.logohi {
				color: #ff9900;
				font-size: 30px;
				height: 30px;
				line-height: 30px;
				position: absolute;
				top: -1px;
			}
			span.topai123{
				position: absolute;
				left: 50px;
				font-size: 20px;
			}
		}


	.left a {
		padding-right: 10px;
		font-size: 14px;
	}
	.left p {
		padding-top: 10px;
	}

	.right h2{
		font-weight: bold;
	}

	.address{
		margin-top: 10px;
	}



}</style>