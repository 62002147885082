<template>
	<div class="common-layout">
		<Header></Header>
		<el-container>
			<el-header>
				<h2>产品开发难？试试店铺选品，快速找到对标店铺，轻松破局!</h2>
			</el-header>
			<!-- <el-main><SearchBox></SearchBox></el-main> -->
			<div class="main-center">
				<!-- <SearchBox></SearchBox> -->
				<StoreSearch></StoreSearch>
			</div>
			<!-- <div class="home-product" v-if="this.$store.state.user.isLogin == false"> -->
			<!-- <div class="home-product" v-if="form.isLoginorNo == false"> -->
			<div class="home-product">
				<p class="home-h3">
				<h3>首页新产品库</h3>
				</p>
				<ProductHome></ProductHome>
			</div>
		</el-container>

		<Footer></Footer>

	</div>
</template>

<script setup>
import Header from "@/components/home/Header"
import Footer from "@/components/home/Footer"
import SearchBox from "@/components/home/SearchBox"
import StoreSearch from "@/components/home/StoreSearch.vue"
import ProductHome from "@/components/home/ProductHome"
import { reactive } from "vue"
import store from '@/store/index.js'

const form = reactive({
	isLoginorNo: false,
})

if (store.state.user.isLogin == true) {
	form.isLoginorNo = true
}

if (store.state.user.isLogin == false) {
	form.isLoginorNo = false
}

// onMounted(() => {

// 	if (store.state.user.isLogin == true) {
// 		form.isLoginorNo = true
// 	}

// })

</script>

<style lang="less" scoped>
header {
	margin-top: 60px;
	height: 100px;
	line-height: 100px;
	background-color: #fdf6ec;
	text-align: center;
	color: #606266;

	h2 {
		font-size: 20px;
		font-weight: bold;
	}

}

.main-center {
	margin-top: 20px;
	// margin-bottom: 20px;
}

.home-h3 {
	padding: 20px;
	text-align: center;

	h3 {
		font-size: 16px;
	}
}

.home-h3 h3::before {
	content: "------------------------------   ";
	color: #cccccc;
	font-size: 12px;
	font-weight: normal;
}

.home-h3 h3::after {
	content: "   ------------------------------";
	color: #cccccc;
	font-size: 12px;
	font-weight: normal;
}

footer {
	background-color: #e8e8e8;
	margin-top: 20px;
	height: 50px;
	line-height: 50px;
}
</style>