import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const Account = () => import("../views/Account/Account")
const Profile = () => import("../views/Account/Profile")
const Follow = () => import("../views/Followbuy/Follow")
const Products = () => import("../views/Product/Products")
const Yanzhen = () => import("../views/Yanzhen/Yanzhen")
const Abaproduct = () => import("../views/Abaproduct/Abaproduct")
const Brandproduct = () => import("../views/Brandproduct/Brandproduct")
const Abakeywords = ()=> import("../views/Abakw/Abakeywords")
const Abaitemonly = ()=> import("../views/Abaonly/Abaitemonly")
const Abafree = ()=> import("../views/Abafree/Abafree")
// 店铺数据查询
const Storedata = ()=> import("../views/Storedata/Storedata")
// 工具使用教程 Course
const Course = ()=> import("../views/Course/Course")
// 网页版产品库
const Productall = ()=> import("../views/Productall/Productall")


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "TOPAI123 跨境电商产品数据库"
    }
  },
  
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      title: "TOPAI123 登录 / 注册"
    }
  },

  {
    path: '/yanzhen/:id([a-zA-Z0-9]+)',
    name: 'Yanzhen',
    component: Yanzhen,
    meta: {
      title: "TOPAI123 验证成功"
    }
  },

  {
    path: '/follow',
    name: 'Followbuy',
    component: Follow,
    meta: {
      title: "产品跟卖库, 为什么这些产品会被跟卖?"
    }
  },

  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: "用户个人中心 - TOPAI123"
    }
  },

  {
    path: '/product',
    name: 'Products',
    component: Products,
    meta: {
      title: "产品库 - TOPAI123"
    }
  },

  {
    path: '/brandproduct',
    name: 'BrandProducts',
    component: Brandproduct,
    meta: {
      title: "TM标产品库 - TOPAI123"
    }
  },

  {
    path: '/abaproduct',
    name: 'Abaproduct',
    component: Abaproduct,
    meta: {
      title: "亚马逊产品开发-ABA关键词选品 - TOPAI123"
    }
  },

  {
    path: '/abakw',
    name: 'Abakeywords',
    component: Abakeywords,
    meta: {
      title: "亚马逊产品开发-ABA关键词过滤 - TOPAI123"
    }
  },

  {
    path: '/abafree',
    name: 'Abafree',
    component: Abafree,
    meta: {
      title: "亚马逊产品开发-关键词热词榜 - TOPAI123"
    }
  },

  {
    path: '/abaonly',
    name: 'Abaitemonly',
    component: Abaitemonly,
    meta: {
      title: "亚马逊产品开发-ABA唯一产品 - TOPAI123"
    }
  },

  {
    path: '/storedata',
    name: 'Storedata',
    component: Storedata,
    meta: {
      title: "TOPAI123 - 店铺数据产品库"
    }
  },

  {
    path: '/course',
    name: 'course',
    component: Course,
    meta: {
      title: "TOPAI123 - 工具使用教程"
    }
  },

  // Productall

  {
    path: '/productall',
    name: 'productall',
    component: Productall,
    meta: {
      title: "TOPAI123 - 产品库各种潜力爆款都在其中"
    }
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

import store from "../store"

router.beforeEach((to, from) => {
  
  document.title = to.meta.title;
  if (to.meta.isAuthRequired == true && store.state.user.isLogin == false) {
    router.push("/account")
  }

})


export default router
