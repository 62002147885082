import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import "@/assets/css/config.css"
// 引入iconfont样式
import "@/assets/iconfont/iconfont.css"
// 引入element-plus
import ElementPlus from 'element-plus'
import * as ElIcons from '@element-plus/icons'
import 'element-plus/dist/index.css'
// import 'element-plus/lib/theme-chalk/index.css'
// 滚动插件的引入
import vue3SeamlessScroll from "vue3-seamless-scroll";

const app = createApp(App)

for (const name in ElIcons) {
    app.component(name, (ElIcons)[name])
}

// createApp(App).use(store)
app.use(store)
    .use(router)
    .use(ElementPlus)
    .use(vue3SeamlessScroll)
    .mount('#app')
